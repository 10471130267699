if(document.getElementById("top-slider")){

  $("#top-slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    dots: false,
    autoplay: true,
    focusOnSelect: false,
    focusOnChange: false,
    infinite: true,
    adaptiveHeight: true,
    speed: 2000,
    autoplaySpeed: 3000,
  });

}


